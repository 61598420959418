
@if (document$ | async; as document) {
  <div class="heading">{{ ('legalPages.' + documentType) | cxTranslate }}</div>
  <div class="document" (click)="downloadDocument(documentType)">
    <app-icon
      iconType="FILE"
      iconSize="MEDIUM"
      [colorTheme]="['NONE']"
    ></app-icon>
    <span>{{ ('legalPages.' + documentType) | cxTranslate }}.pdf</span>
  </div>
  <div class="info-text">
    {{ document.description }}{{ 'legalPages.status' | cxTranslate }} {{ document.updatedOn | kurzDate }}
  </div>
}

@if (innerHtml$ | async; as innerHTML) {
  <app-html-deserializer [string]="innerHTML" allowStyles></app-html-deserializer>
}
